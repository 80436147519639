export default {
    nav: {
        planning: {
            label: 'Operations',
            operations: 'Operations',
            forwarding: 'Chartering',
            archive: 'Archive'
        }
    },
    forwarding: {
        filters: {
            tabs: {
                toSell: 'To sell',
                waitingLoad: 'Sold',
                waitingPod: 'POD pending',
            }
        },
        header: {
            toSell: 'To sell',
            waitingLoad: 'Sold',
            waitingPod: 'POD pending',
            finished: 'Finished',
            customer: 'Customer',
            charter: 'Charter',
            load: 'Load',
            unload: 'Unload',
            totalKm: 'Km',
            prices: 'Price',
            margin: 'Margin',
        },

    },
    header: {
        archive: 'Archive',
        plannedTrips: 'Operations',
        openTrips: 'Open trips',
        truck: 'Truck',
        fuel: 'Fuel',
        customer: 'Customer',
        trailer: 'Trailer',
        startingActivity: 'First and last activity of trip',
        totalKm: 'Total Km',
        collapse: 'Collapse',
        hide: 'Hide',
        comment: 'Comment',
        driver: 'Driver',
        chat: 'Chat'
    },
    noItems: ' No Items Found',
    activity: {
        overview: {
            title: 'Activity archive',
            proofOfDelivery: 'POD'
        },
        saveButton: 'Save',
        saveAndCalculateButton: 'Save & Recalculate',
        saveAndFinalizeButton: 'Ready to invoice',
        saveAndSendButton: 'Save and send to chat',
        skipValidation: 'Skip Validation',
        buttons: {
            edit: 'Edit activity',
            delete: 'Delete activity',
            unplanTrip: {
                label: 'Un-plan trip',
                disableLabel: 'Activity needs to have trip assigned to unplan a trip'
            },
            replanTrip: 'Move trip',
            finalize: 'Finalize activity',
            create: 'Add activity',
            removePriority: 'Remove priority',
            addPriority: 'Priority',
            moveTrip: 'Move trip',
            sendToClient: 'Send update to client',
            sendToDriver: 'Send to:',
            sendToDriverOption: 'or',
            sendToTransicsHint: 'Send current activity to Transics device via chat',
            sendToDriverAppHint: 'Send whole planning to Driver App',
        },
        changeStatusModal: {
            header: 'Change activity status',
        },
        statuses: {
            draft: 'Draft',
            planned: 'Planned',
            eta: 'Driving',
            arrived: 'Arrived',
            waiting: 'Waiting',
            driving: 'Driving',
            working: 'Working',
            started: 'Started',
            finished: 'Finished',
            finalized: 'Finalized',
            executing: 'Executing',
            canceled: 'Canceled',
            new: 'New'
        },
        priority:{
            checkbox:{
                label: 'Priority'
            }
        },
        times: {
            label: 'Load/Unload dates'
        },
        field: {
            activity: {
                label: 'Activity'
            },
            allocatedTruck: {
                label: 'Allocated truck'
            },
            allocatedTrailer: {
                label: 'Allocated trailer'
            },
            allocatedDriver: {
                label: 'Allocated driver',
                notMatchMessage: 'Assigned driver {{name}} is different than the one from transics {{transics}}',
                onlyTransicsMessage: 'No assigned driver showing transics driver {{transics}} info'
            },
            id: {
                label: 'ID'
            },
            distanceTo: {
                label: 'Distance to'
            },
            bookingReference: {
                label: 'Booking Reference'
            },
            unitLicensePlate: {
                label: 'Unit License Plate'
            },
            allocatedCustomerGroup: {
                label: 'Allocated customer group',
                info: 'Can be changed only if the activity is of the type of customer group allocation'
            },
            cargoSpecification: {
                label: 'Cargo Specification'
            },
            unumber: {
                label: 'Un Number'
            },
            proofOfDelivery: {
                label: 'POD'
            },
            class19: {
                label: 'Class (1-9)'
            },
            packgroup: {
                label: 'Packgroup (1-3)'
            },
            trip: {
                label: 'Dossier trip'
            },
            lq: {
                label: 'LQ'
            },
            mrnNumber: {
                label: 'Mrn Number'
            },
            additionalInformation: {
                label: 'Additional Information'
            },
            type: {
                label: 'Activity type'
            },
            goodsDescription: {
                label: 'Description'
            },
            orderedArrivalDatetimeFrom: {
                label: 'Date from'
            },
            orderedArrivalDatetimeUntil: {
                label: 'Date to'
            },
            instructions: {
                label: 'Driver instructions'
            },
            location: {
                label: 'Location'
            },
            reference: {
                label: 'Activity reference'
            },
            remarks: {
                label: 'Remarks'
            },
            status: {
                label: 'Status'
            },
            startKm: {
                label: 'KM start'
            },
            endKm: {
                label: 'KM end'
            },
            plannedKm: {
                label: 'Planned km'
            },
            drivenKm: {
                label: 'Driven km'
            },
            kmDeviation: {
                label: 'Km deviation'
            },
            kmDeviationPercent: {
                label: 'Km deviation percentage'
            },
            kmDeviationRemarks: {
                label: 'Km deviation remarks'
            },
            statusDraftAt: { label: 'Draft At', },
            statusPlannedAt: { label: 'Planned At', },
            statusEtaAt: { label: 'Driving At', },
            statusArrivedAt: { label: 'Arrived At', },
            statusDrivingAt: { label: 'Driving At', },
            statusWaitingAt: { label: 'Waiting At', },
            statusStartedAt: { label: 'Started At', },
            statusFinishedAt: { label: 'Finished At', abbreviation: 'FA' },
            statusFinalizedAt: { label: 'Finalized At', },
            statusCanceledAt: { label: 'Canceled At', },
            dossier: {
                link: 'Open dossier'
            },
            companyName: {
                label: 'Company name',
                placeholder: 'You need to select dossier trip to save address with company name'
            },
            uploadPOD: {
                label: 'Upload POD'
            },
            finalize: {
                label: 'Ready for invoice at'
            },
            undoFinish: {
                label: 'Undo finish'
            }
        },
        modal: {
            title: {
                label: 'Activity Finalize'
            },
            notification: {
                missingStatusFinalizedAt: 'Fill activity date Finished At'
            }
        },
        dossierLink: {
            popupContent: 'Select to open dossier {{id}}'
        }
    },
    row: {
        comments: 'Add notes...',
        updated: 'Row has recently been updated',
        popup: {
            customerGroup: {
                allocated: 'Customer group of active activity ({{current}}) does not match allocated customer group ({{allocated}})'
            },
            hiddenActivities: '{{number}} activities in between'
        }

    },
    expandRow: {
        content: {
            trip: 'Trip',
            tripNumber: 'Trip number',
            invoiceReference: 'Reference',
            location: 'Location',
            city: 'Location',
            gpsCoordinates: 'GPS coordinates',
            datesAndTimes: 'Dates and Times',
            plannedArrivalTime: 'Planned arrival time',
            plannedStart: 'Planned start',
            plannedEnd: 'Planned end',
            estimated: 'Estimated (ETA)',
            instructions: 'Driver instructions',
            remarks: 'Remarks',
            odometer: 'Odometer',
            addDriver: 'Add driver',
            addTrailer: 'Add trailer',
            editDriver: 'Driver',
            firstDriver: 'First driver: {{driver}}',
            tachoConnectionNotConfigured: 'Tacho connection not configured',
            noTachoInformationAvailable: 'No tacho information available',
            secondDriver: 'Second driver: {{driver}}',
            transicsDriver: 'Transics driver: {{driver}}',
            editFirstDriver: 'First driver',
            editSecondDriver: 'Second driver',
            editTrailer: 'Trailer',
            editTruck: 'Truck',
            nextService: 'Next service',
            emailHaulier: 'Email of haulier',
            assetAllocation: 'Assets allocation',
            dossier: 'Dossier',
            customer: 'Customer',
            customerGroup: 'Customer group',
            allocationPlaceholder: 'Automatic allocation',
            allocationPlaceholderDriver: 'No driver allocated',
            showAllAllocation: 'Show all allocations',
            tripRemarks: 'Trip remarks',
            dossierRemarks: 'Internal dossier remarks',
            reference: 'Activity reference',
            truckGpsLocation: 'Truck GPS location',
            mobilePhoneGpsLocation: 'Mobile phone GPS location',
            lastGpsUpdateTime: 'Last updated at',
        },
        contact: {
            title: 'Contact'
        },
        sendToYard: {
            title: 'Send to Yard'
        },
        sendToDriver: {
            title: 'Send to Driver'
        },
        sendTerminal: {
            title: 'Send Terminal'
        },
        editTrip: {
            title: 'Edit trip',
            sendActivities: 'Send activities',
            activitiesSended: 'Activities was successfully sended',
            error: {
                selectActivity: 'Select at least one activity',
                setTruckMail: 'Truck company don\'t have address email',
            },
            activitySended: 'Activity was sended',
            activityAccepted: 'Activity was accepted',
            activityRejected: 'Activity was rejected',
        },
        archive: {
            title: 'Activity archive',
            finalize: {
                label: 'Check POD'
            },
            undoFinish: {
                label: 'Undo finish'
            }
        },
        trip: {
            title: 'Trip Route Schedule'
        },
        activity: {
            modal: {
                sendStatusEmail: {
                    header: 'Send status email',
                    trigger: 'Send status email'
                },
            },
            updateStatus: 'Update Status',

            buttons: {
                sendInstructions: 'Send instructions',
                draft: 'Change to Draft',
                planned: 'Plan activity',
                eta: 'Start driving',
                arrived: 'Driver Arrived',
                waiting: 'Driver Waiting',
                started: 'Started Activity',
                finished: 'Finished Activity',
                finalized: 'Finalize Activity',
                canceled: 'Cancel Activity',
                changeLocationActivity: 'Change location activity',
                changeDateActivity: 'Change activity date',
            },
            addButton: 'Add activity',
            deleteActivityButton: 'Delete activity',
            new: 'New',
            information: 'Activity Information',
            details: 'Details',
            cancelTrip:{
                popup: 'Trip contains activities that are working/finished or trip has no dossier assigned.',
                notification: 'Trip successfully canceled'
            },
            uncancelTrip:{
                popup: 'Trip contains activities that are working/finished or trip has no dossier assigned.',
                notification: 'Trip successfully uncanceled'
            }
        },
        truck: {
            noTruck: 'Currently there is no truck attached',
            noChat: 'Currently there is no chat available',
            generalInformation: 'Truck information',
            activitiesTruck: {
                header: 'Activities truck',
                trip: 'Trip',
                activity: 'Activity',
                trailer: 'Trailer',
                location: 'Location',
                status: 'Status',

            }
        },
        trailer: {
            noTrailer: 'Currently there is no trailer attached',
            generalInformation: 'Trailer information',
            performanceInformation: 'Performance',
            activitiesTrailer: {
                header: 'Trailer trips',
                trip: 'Trip',
                truck: 'Truck',
                unloadingCity: 'Unloading city',
                loadingCity: 'Loading city',
                tripDistance: 'Kilometers Trailer Trip',

            }

        },
    },
    truck: {
        field: {
            customerAllocation: {
                label: 'Customer allocation'
            }
        }
    },
    trip: {
        label: 'Trip',
        charterTrip: 'Charter trip',
        undoCharterTrip: 'Undo charter trip',
        field: {
            remarks: {
                label: 'Trip remarks'
            }
        }
    },
    dossier: {
        field: {
            remarks: {
                label: 'Internal dossier remarks'
            },
            invoiceReference: {
                label: 'Reference'
            },
        }
    },
    tachoEvent: {
        assistantDrivingTimes: 'Assistant driving times',
        total: 'Total',
        maximum: 'Maximum',
        minimum: 'Minimum',
        remaining: 'Remaining',
        ID: 'ID:',
        transicsID: 'TransicsID:',
        code: 'Code:',
        description: 'Description:',
        started: 'Started:',
        drivingTimeBelowMaximum: 'Driving Time Below Maximum',
        dailyDrivingTime: 'Daily Driving Time',
        daysOfDrivingBiggerThan9h: 'Days Of Driving Bigger Than 9h',
        weeklyDrivingTime: 'Weekly Driving Time',
        dailyAmplitude: 'Daily Amplitude',
        workWeek: 'Work Week',
        assistantRestPeriods: 'Assistant rest periods',
        breakBelowMinimum: 'Break Below Minimum',
        dailyRest: 'Daily Rest',
        previousDailyRest: 'Previous Daily Rest',
        compensableWeeklyRest: 'Compensable weekly rest',
        weeklyRestMinus1: 'Weekly Rest Minus1',
        weeklyRestMinus2: 'Weekly Rest Minus2',
        weeklyRestMinus3: 'Weekly Rest Minus3',
    },
    filters: {
        tab: {
            planning: 'Operations',
            trips: 'Trips'
        },
        fleetNumber: {
            label: 'Fleet Number',
            placeholder: 'Truck fleet number',
        },
        licensePlate: {
            label: 'License Plate',
            placeholder: 'Truck license plate',
        },
        customerAllocation: {
            label: 'Customer Allocation',
            placeholder: 'Customer name',
        },
        customer: {
            label: 'Customer',
            placeholder: 'Customer name',
        },
        activityLocation: {
            label: 'Location',
            placeholder: 'Activity location',
        },
        districts: {
            label: 'Districts',
            placeholder: 'Select district',
        },
        date: {
            label: 'Date',
            placeholderStart: 'Start date',
            placeholderEnd: 'End date',
        },
        clearButton: {
            label: 'Clear filters'
        },
        section: {
            label: 'Section'
        },
        planning: {
            label: 'Operations'
        },
        status: {
            label: 'Trip status',
        },
        openTrips: {
            label: 'Open trips'
        },
        collapsed: {
            label: 'Collapse',
        },
        showFinished: {
            label: 'Show finished activities',
        },
        showFinalized: {
            label: 'Show activities ready for invoice',
        },
        hideColumns: {
            label: 'Hide columns',
        },
        addLine: {
            label: 'Add line',
        },
        removeLine: {
            label: 'Remove line',
        },
        inactive: {
            label: 'Active / Inactive',
            all: 'All',
            active: 'Active ',
            inactive: 'Inactive',
        },
        assignedTo: {
            label: 'Assigned to',
        },
        owner: {
            label: 'Owner',
        },
        customerGroup: {
            label: 'Customer Group',
            placeholder: 'Group of customer',
        },
        currentCustomerGroup: {
            label: 'Current Customer Group',
            placeholder: 'Group of customer',
        },
        futureCustomerGroup: {
            label: 'Future Customer Group',
            placeholder: 'Group of customer',
        },
        all: {
            label: 'All',
        },
        waiting: {
            label: 'Waiting',
        },
        busy: {
            label: 'Busy',
        },
        limit: {
            label: 'Limit',
        },
        autoRegenerate: {
            label: 'Each 5-minute regenerates sales plan'
        },
        flaggedRows: {
            label: 'Flagged rows',
            all: 'All',
            flagged: 'Flagged'
        },
        weekendPause: {
            label: 'Weekend pause'
        },
        dossierNumber: {
            label: 'Dossier number',
            placeholder: 'Dossier number',
        },
        driver:{
            label: 'Driver',
            placeholder: 'Driver',
        }
    },
    modalMoveTrip: {
        header: 'Move whole trip {{id}} to a specified truck',
        buttons: {
            move: 'Move trip'
        }
    },
    modalAllocateCustomer: {
        header: 'Allocate customer group to selected trucks',
        field: {
            customerGroup: {
                label: 'Customer group'
            }
        },
        buttons: {
            allocate: 'Allocate'
        },
        required: {
            customerGroup: 'Customer Group is required'
        }
    },
    modalCreateTrailer: {
        header: 'Create new trailer and allocate',
    },
    modalCreateTruck: {
        header: 'Create new truck and allocate',
    },
    modalCreateDriver: {
        header: 'Create new driver and allocate',
    },
    modalSwap: {
        noActivities: 'No activities',
        swapLocationLabel: 'Swap location',
        secondTruck: 'Truck 2',
        description: 'By selecting an activity, you designate a set of activities(including the selected activity) that will be swapped between trucks.\nOnly the selected set of activities will be moved from one truck to the second one.',
        header: 'Swap modal',
        swapLocation: {
            description: 'The place and time of the swap between two trucks that will be planned'
        },
        error: {
            header: 'Action Forbidden',
            message: 'Activity cannot be moved because it cannot be placed before the last activity in the opposite set of activities, which is not swapped.'
        },
        resultText: 'Result'
    },
    tripSplitModal: {
        header: 'Trip split modal',
        description: 'This moves all activities after the trailer drop activity to a new trip, prepended by a trailer pick up at the location of the trailer drop.\nAre you sure you want to continue?',
    },
    toolbar: {
        buttons: {
            showActivityArchive: 'Show archive',
            groupBy: {
                label: 'Group by',
                options: {
                    NO_GROUPING: 'No grouping',
                    CUSTOMER_GROUP: 'Customer group'
                }
            },
            swap: {
                label: 'Trailer swap',
                warning: 'You need to select 2 trucks to start swapping activities'
            },
            tripSplit:{
                label: 'Trip split'
            }
        }
    },
    form: {
        deleteConfirmationActivity: 'Are you sure you want to delete this activity?',
    },
};
