import React from 'react';
import { NavMenu, NavItem } from 're-cy-cle';
import { t } from '@code-yellow/spider';

export const renderMasterDataMenu = () => {
    return (
        <NavItem
            title={t('masterData:nav.data.label')}
            to="/master-data/trailer/overview"
            activePath="/master-data"
        />
    );
};

export const renderMasterDataSubMenu = () => {
    return (
        <NavMenu data-test-menu-masterdata>
            <NavItem
                title={t('masterData:nav.masterdata.truck')}
                to="/master-data/truck/overview"
                activePath="/master-data/truck/"
            />
            <NavItem
                title={t('masterData:nav.masterdata.trailer')}
                to="/master-data/trailer/overview"
                activePath="/master-data/trailer/"
            />
            <NavItem
                title={t('masterData:nav.masterdata.driver')}
                to="/master-data/driver/overview"
                activePath="/master-data/driver/"
            />
            <NavItem
                title={t('masterData:nav.masterdata.goodsDescription')}
                to="/master-data/goods-description/overview"
                activePath="/master-data/goods-description/"
            />
        </NavMenu>
    );
};

